import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Container,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import FitnessCenterOutlinedIcon from "@material-ui/icons/FitnessCenterOutlined";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <h1>Welcome</h1>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionArea>
                  <Link
                    to="/products"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <FitnessCenterOutlinedIcon
                        style={{ fontSize: "60px", marginBottom: "30px" }}
                      />
                      <h2>Products</h2>
                    </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardActionArea>
                  <Link
                    to="/requests"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ListOutlinedIcon
                        style={{ fontSize: "60px", marginBottom: "30px" }}
                      />
                      <h2>My requests</h2>
                    </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;

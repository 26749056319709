import React, { Component } from "react";
import { Link } from "react-router-dom";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  IconButton,
  Tooltip,
  Button,
  Paper,
  Container,
} from "@material-ui/core";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

class Cart extends Component {
  render() {
    return <Container>{this.renderCart()}</Container>;
  }

  renderCart = () => {
    const { cart } = this.props;
    return (
      <div className="cart-Container container">
        <h1>Cart</h1>
        {cart.length > 0 ? this.renderCartNotEmpty() : this.renderCartEmtpy()}
      </div>
    );
  };

  renderCartEmtpy = () => {
    return (
      <React.Fragment>
        <h2 style={{ marginBottom: "4vh" }}>
          You have no Elements in your cart!
        </h2>
        <Link to="/products" style={{ textDecoration: "none" }}>
          <Button variant="contained">To Products</Button>
        </Link>
      </React.Fragment>
    );
  };

  renderCartNotEmpty = () => {
    const { cart, onRemoveFromCart } = this.props;
    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell align="right">QTY</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.map((e) => (
                <TableRow key={e.sku}>
                  <TableCell>{e.sku}</TableCell>
                  <TableCell>{e.name}</TableCell>
                  <TableCell align="right">{e.amt}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Delete">
                      <IconButton onClick={() => onRemoveFromCart(e.sku)}>
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "4vh" }}
          onClick={() => this.props.history.push("/checkout")}
        >
          Checkout
        </Button>
      </React.Fragment>
    );
  };
}

export default Cart;

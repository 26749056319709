import React from 'react';

const NotFound = () => {
    return ( <React.Fragment>
        <div className="container">
            <h1>404 - NOT FOUND</h1>
        </div>
    </React.Fragment> );
}
 
export default NotFound;
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ProtectedRoute from "./components/common/protectedRoute";
import AdminRoute from "./components/common/adminRoute";
import Navbar from "./components/navbar";
import Login from "./components/login";
import Products from "./components/products";
import Cart from "./components/cart";
import Requests from "./components/requests"
import NotFound from "./components/not-found"
import authService from "./services/authService";
import * as cartService from "./services/cartService";
import { convertFromApi } from "./interface/cartInterface";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/home";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Checkout from "./components/checkout";
import CreateUser from "./components/createUser";
import AccessDenied from "./components/accessDenied";

class App extends Component {
  constructor(props){
    super(props);
    const user = authService.getCurrentUser();
    if(user){
      authService.setToken();
    }
    this.state = {
      user,
      cart: [],
      filter: []
    }
  }
  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme} >
        <ToastContainer />
        {this.isLoggedIn() ? <Navbar user={this.state.user} cart={this.state.cart}></Navbar> : null}
        <Switch>
          <ProtectedRoute path="/" exact component={Home} />
          <Route path="/login" render={(props) => (<Login {...props} user={this.state.user} onLogin={this.handleLogin}></Login>)}  />
          <ProtectedRoute
            path="/products"
            render={(props) => (
              <Products
                {...props}
                onAddToCart={this.handleAddToCart}
              ></Products>
            )}
          />
          <ProtectedRoute
            path="/cart"
            render={(props) => (
              <Cart
                {...props}
                cart={this.state.cart}
                onRemoveFromCart={this.handleRemoveFromCart}
              ></Cart>
            )}
          />
          <ProtectedRoute
            path="/checkout"
            render={(props) => (
              <Checkout
                {...props}
                onUpdateCart={this.handleUpdateCart}
                cart={this.state.cart}
              ></Checkout>
            )}
          />
          <ProtectedRoute path="/requests" render={ props => <Requests {...props} ></Requests>} />
          <AdminRoute path="/create/user" render={props => <CreateUser {...props}></CreateUser>} />
          <Route path="/access-denied" component={AccessDenied} />
          <Route path="/not-found" component={NotFound} ></Route>
          <Redirect from="/" to="not-Found"></Redirect>
        </Switch>
        </ThemeProvider>
      </React.Fragment>
    );
  }

  async componentDidMount() {
    if(this.state.user){
        let { data: cart } = await cartService.getCart();
        cart = convertFromApi(cart);
        this.setState({ cart });
    }
  }

  handleAddToCart = async (product) => {
    const amt = parseInt(product.amt) || 0;
    if(amt <= 0)
      return

    const previousCart = this.state.cart;
    const cart = [...this.state.cart];
    const idx = cart
      .map(function (e) {
        return e.sku;
      })
      .indexOf(product.sku);
    if (idx !== -1) {
      cart[idx] = { ...cart[idx] };
      cart[idx].amt += parseInt(product.amt);
    } else {
      cart.push({ sku: product.sku, amt: parseInt(product.amt), name: product.name });
    }
    this.setState({ cart });

    //call the server
    try {
      await cartService.addToCart({ productId: product.sku, amt: 1, name: product.name })
      .then(
        toast.info("Item was added to your cart")
      );
    } catch (ex) {
      this.setState({ cart: previousCart });
    }
  };

  handleRemoveFromCart = async (productId) => {
    const previousCart = this.state.cart;
    let cart = [...this.state.cart];
    const idx = cart
      .map(function (e) {
        return e.sku;
      })
      .indexOf(productId);

    cart = cart.filter((e) => e !== cart[idx]);
    this.setState({ cart });

    //call the server
    try {
      await cartService.removeFromCart(productId);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        toast.error("Item has already been deleted");
      } else {
        this.setState({ cart: previousCart });
      }
    }
  };

  handleUpdateCart = async () => {
    const newCart = await cartService.getCart();
    this.setState({cart: newCart});
  }

  handleLogin = () => {
    this.setState({user: authService.getCurrentUser()});
  }

  isLoggedIn = () => {
    const {user} = this.state;
    // const is = user === {} || user === null || user === false;
    // console.log("is", is);
    return !(user === {} || user === null || user === false)
  }
}



export default App;

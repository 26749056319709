import httpService from "./httpService";
import * as config from "../config.json";

const endpoint = config.apiEndpoint + "/cart.php";

export function getCart() {
  return httpService.get(endpoint);
}

export function addToCart(productObj) {
  return httpService.post(endpoint, productObj);
}

export function removeFromCart(productId) {
  return httpService.delete(endpoint, {
    data: { productId },
  });
}

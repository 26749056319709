import React, { Component } from "react";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Button,
} from "@material-ui/core";
import httpService from "../services/httpService";
import * as config from "../config.json";

class CreateUser extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="wrapper" style={{ marginTop: "40px" }}>
          <form onSubmit={this.handleSubmit}>
            <h1>Create new User</h1>
            <Grid container spacing={3} justify="center">
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <Input
                    type="text"
                    name="username"
                    onChange={this.handleChange}
                  ></Input>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                  ></Input>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="center">
              <Grid item xs={6}>
                <Button
                  type="submit"
                  name="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
    );
  }

  handleChange = (e) => {
    const elemName = e.target.name;
    let stateObj = { ...this.state };
    stateObj[elemName] = e.target.value;
    this.setState(stateObj);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    httpService.post(config.apiEndpoint + "/createUser.php", this.state);
  };
}

export default CreateUser;

import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#333',
        },
        secondary: {
            main: "#e30613"
        }
    },
    typography: {
        fontFamily: [
            'Roboto Condensed',
        ]
    }
});

export default theme;
import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as checkoutService from "./../services/checkoutService";
import { toast } from "react-toastify";
// import ControlledInput from "./common/controlledInput";

const Checkout = (props) => {
  const { cart, history, onUpdateCart } = props;
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (e) => {
    const payload = { shipping: e, items: cart };
    console.log(payload);

    try {
      const res = await checkoutService.postRequest(payload);
      console.log(res);
      toast.success("Request successully sent");
      // Cart gets updatet on DB in backend
      // -> refresh cart state
      // onUpdateCart();
      // history.push("");
    } catch (ex) {}
  };

  return (
    <Container>
      <h1 style={{ marginBottom: "4vh" }}>Shipping Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {/* <ControlledInput
                    label="Firstname"
                    name="firstname"
                    register={register}
                    errors={errors}
                  ></ControlledInput> */}
                  <FormControl fullWidth error={errors.firstname}>
                    <InputLabel>Firstname</InputLabel>
                    <Input
                      name="firstname"
                      inputRef={register({ required: true })}
                    />
                    {errors.firstname &&
                    errors.firstname.type === "required" ? (
                      <FormHelperText>Firstname is required</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth error={errors.lastname}>
                    <InputLabel>Lastname</InputLabel>
                    <Input
                      name="lastname"
                      inputRef={register({ required: true })}
                    />
                    {errors.lastname && errors.lastname.type === "required" ? (
                      <FormHelperText>Lastname is required</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={10}>
                  <FormControl fullWidth error={errors.street}>
                    <InputLabel>Street</InputLabel>
                    <Input
                      name="street"
                      inputRef={register({ required: true })}
                    />
                    {errors.street && errors.street.type === "required" ? (
                      <FormHelperText>Street is required</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth error={errors.nr}>
                    <InputLabel>Nr</InputLabel>
                    <Input name="nr" inputRef={register({ required: true })} />
                    {errors.nr && errors.nr.type === "required" ? (
                      <FormHelperText>Nr is required</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={2}>
                  <FormControl fullWidth error={errors.zip}>
                    <InputLabel>Zip</InputLabel>
                    <Input
                      name="zip"
                      inputRef={register({
                        required: true,
                        minLength: 4,
                        maxLength: 5,
                      })}
                    />
                    {errors.zip && errors.zip.type === "required" ? (
                      <FormHelperText>Zip is required</FormHelperText>
                    ) : null}
                    {errors.zip && errors.zip.type === "minLength" ? (
                      <FormHelperText>min length: 4</FormHelperText>
                    ) : null}
                    {errors.zip && errors.zip.type === "maxLength" ? (
                      <FormHelperText>max length: 5</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={9} sm={10}>
                  <FormControl fullWidth error={errors.city}>
                    <InputLabel>City</InputLabel>
                    <Input
                      name="city"
                      inputRef={register({ required: true })}
                    />
                    {errors.city && errors.city.type === "required" ? (
                      <FormHelperText>City is required</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={errors.country}>
                    <InputLabel>Country</InputLabel>
                    <Input
                      name="country"
                      inputRef={register({ required: true })}
                    />
                    {errors.country && errors.country.type === "required" ? (
                      <FormHelperText>Country is required</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "5vh" }}>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Request now
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Hidden only={["sm", "xs"]}>
          <Grid item md={6}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>QTY</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>NAME</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map((item) => (
                    <TableRow key={item.sku}>
                      <TableCell>{item.amt}</TableCell>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell>{item.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default Checkout;

import httpService from "./httpService";
import * as config from "../config.json";

const endpoint = config.apiEndpoint + "/handler.php";
// const endpoint = config.apiEndpoint + "/testhandler.php";

export function postRequest(requestObj) {
  console.log(requestObj)
  return httpService.post(endpoint, requestObj);
}


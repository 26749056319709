import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../img/hammer_logo_white.png";
import cartIcon from "../img/icon/cart.svg";
import cartFillIcon from "../img/icon/cart-fill.svg";
import {
  Badge,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Hidden,
} from "@material-ui/core";
import authService from "./../services/authService";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import "./navbar.css";

class Navbar extends Component {
  state = {};

  styles = {
    logout: {
      marginRight: "1vw",
      marginLeft: "3vh",
      whiteSpace: "nowrap",
    },
  };

  render() {
    const { cart, user } = this.props;
    let totalCartAmount = 0;
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      totalCartAmount = totalCartAmount + parseInt(element.amt);
    }
    return (
      <React.Fragment>
        <AppBar position="sticky" style={{ backgroundColor: "#333" }}>
          <Toolbar>
            <Link style={{ marginRight: "7vw" }} to="/">
              <img width="150" src={logo} alt="" />
            </Link>
            {user === {} || user === null
              ? ""
              : this.renderLoggedInNav(totalCartAmount)}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }

  handleMouseOver = (e) => {
    e.target.src = cartFillIcon;
  };

  handleMouseOut = (e) => {
    e.target.src = cartIcon;
  };

  handleLogout = (e) => {
    e.preventDefault();
    authService.logout();
    window.location = "/";
  };

  renderLoggedInNav = (totalCartAmount) => {
    return (
      <React.Fragment>
        <Container style={{ display: "flex" }}>
          <Hidden only={["xs", "sm"]}>
            <Typography variant="h6" component="span">
              <NavLink
                className="navlink"
                activeClassName="active"
                exact
                to="/products"
                activeStyle={{ fontWeight: "bold" }}
              >
                Products
              </NavLink>
            </Typography>
            <Typography variant="h6" component="span">
              <NavLink
                className="navlink"
                activeClassName="active"
                exact
                to="/requests"
                activeStyle={{ fontWeight: "bold" }}
              >
                My Requests
              </NavLink>
            </Typography>
          </Hidden>
        </Container>
        <div style={{ flexGrow: "1" }}></div>
        <div>
          <NavLink to="/cart" style={{ padding: "0" }}>
            <IconButton aria-label="cart">
              <Badge badgeContent={totalCartAmount} color="secondary">
                <ShoppingCartIcon style={{ color: "#fff" }} />
              </Badge>
            </IconButton>
          </NavLink>
        </div>
        <div>
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <ExitToAppIcon
              style={this.styles.logout}
              onClick={(e) => this.handleLogout(e)}
            />
          </Hidden>
          <Hidden only={["xs"]}>
            <Typography variant="h6" component="span">
              <a
                component="a"
                href="#"
                className="navlink"
                style={this.styles.logout}
                onClick={(e) => {
                  this.handleLogout(e);
                }}
              >
                Logout
              </a>
            </Typography>
          </Hidden>
        </div>
      </React.Fragment>
    );
  };
}

export default Navbar;

export function convertFromApi(input) {
  let output = [];
  input.forEach((element) => {
    let item = {
      sku: element.productId,
      amt: parseInt(element.amt),
      name: element.name
    };
    output.push(item);
  });
  return output;
}

import { Container } from "@material-ui/core";
import React from "react";

const AccessDenied = () => {
  return (
    <React.Fragment>
      <Container style={{ marginTop: "40px" }}>
        <h1>You do not have permission to view this page!</h1>
      </Container>
    </React.Fragment>
  );
};

export default AccessDenied;

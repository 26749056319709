import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getProducts } from "../services/productsService";
import "./products.css";
import {
  Paper,
  Button,
  Container,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Spacer from "./common/spacer";

import { search } from "./utils/search";

class Products extends Component {
  state = {
    data: [],
    filterCategory: "",
    searchedData: [],
    searchText: "",
  };

  render() {
    const { onAddToCart } = this.props;
    return (
      <React.Fragment>
        <Container style={{ marginTop: "5vh" }}>
          {this.state.data.length <= 0 ? (
            <CircularProgress style={{ margin: "0 auto", display: "block" }} />
          ) : (
            <React.Fragment>
              <div
                style={{
                  marginBottom: "3vh",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FormControl>
                  <InputLabel>Search</InputLabel>
                  <Input
                    onChange={this.handleSearch}
                    placeholder="type to search..."
                  />
                  {this.state.searchText.length <= 1 ? (
                    <FormHelperText id="component-helper-text">
                      min. 2 characters
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>NAME</TableCell>
                      <TableCell align="right">STOCK</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.searchedData.map((e) => (
                      <TableRow key={e.sku}>
                        <TableCell>{e.sku}</TableCell>
                        <TableCell>{e.name}</TableCell>
                        <TableCell align="right">{e.stock}</TableCell>
                        <TableCell align="right">
                          <Input
                            className="input-amt"
                            name="amount"
                            type="number"
                            min="0"
                            defaultValue="0"
                            onChange={(ev) => this.handleAmountChange(ev, e)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onAddToCart({
                                sku: e.sku,
                                name: e.name,
                                amt: e.amt,
                              })
                            }
                          >
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </Container>
        <Spacer height="40px" />
      </React.Fragment>
    );
  }

  componentDidMount = async () => {
    const response = await getProducts();
    this.setState({ data: response.data, searchedData: response.data });
  };

  getCategories = () => {
    let categories = [];
    this.state.data.map((e) =>
      categories.includes(e.produktgattung)
        ? null
        : categories.push(e.produktgattung)
    );
    return categories;
  };

  handleFilter = (value) => {
    this.setState({ filterCategory: value });
  };

  handleSearch = (e) => {
    const searchText = e.target.value;
    const data = [...this.state.data];
    if (searchText.length >= 2) {
      const results = search(searchText, ["sku", "name"], data);
      this.setState({ searchedData: results, searchText });
    } else if (this.state.searchedData !== this.state.data) {
      this.setState({ searchedData: this.state.data, searchText });
    }
  };

  handleAmountChange = (e, item) => {
    if (e.target.value >= 0) {
      let prev = [...this.state.searchedData];
      const index = prev.indexOf(item);

      const newItem = {
        ...this.state.searchedData[index],
        amt: e.target.value,
      };
      prev[index] = newItem;
      this.setState({ searchedData: prev });
    } else {
      e.target.value = 0;
    }
  };
}

export default Products;

export function search(value, keys, data) {
    let result = [];
    const re = new RegExp(value, "gi");
    for(let i = 0; i<keys.length; i++){
        const key = keys[i];
        const filtered = data.filter( d => d[key].match(re))
        filtered.forEach(item => {
            if(i===0) result.push(item)
            else if(!result.includes(item))
                result.push(item);
        })
    }
    return result;
}

import React, { useEffect, useState } from "react";
import { getRequests } from "../services/requestsService";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Requests = (props) => {
  const [requests, setRequests] = useState([]);
  const [init, setInit] = useState(false);

  const manageRequests = (inputRequests) => {
    let reqs = [];
    for (let i = 0; i < inputRequests.length; i++) {
      const r = inputRequests[i];
      //append product
      if (reqs.map((e) => e.id).includes(r.id)) {
        let req = [...reqs];
        req = req.filter((e) => e.id === r.id);
        req[0].products.push({
          id: r.productId,
          qty: r.qty,
          name: r.productName,
        });
      }
      //new request
      else {
        let req = {
          id: r.id,
          products: [{ id: r.productId, qty: r.qty, name: r.productName }],
          created: r.created,
        };
        reqs.push(req);
      }
    }
    return reqs;
  };

  useEffect(() => {
    async function fetchData() {
      const response = await getRequests();
      const reqs = manageRequests(response.data);
      setRequests(reqs);
      setInit(true);
    }
    fetchData();
  }, []);

  const renderNoRequests = () => {
    const { history } = props;
    return (
      <React.Fragment>
        <div style={{ marginBottom: "2vw" }}>You have no Request</div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/products")}
        >
          To Products
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Container>
      <h1>Your past Requests</h1>
      <div style={{ marginTop: "5vh" }}></div>
      {requests.length === 0 && init === true ? renderNoRequests() : null}
      {requests.length === 0 && init === false ? (
        <CircularProgress style={{ margin: "0 auto", display: "block" }} />
      ) : (
        requests.map((r) => (
          <Accordion key={r.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Request # {r.id}
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table aria-label="order table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>NAME</TableCell>
                      <TableCell align="right">QTY</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {r.products.map((p) => (
                      <TableRow key={r.id + "_" + p.id}>
                        <TableCell>{p.id}</TableCell>
                        <TableCell>{p.name}</TableCell>
                        <TableCell align="right">{p.qty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Container>
  );
};

export default Requests;

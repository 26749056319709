import axios from "axios";
import { toast } from "react-toastify";
import logout from './logoutService';

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if(error.response.status === 401){
    console.log(error.response);
    // try getting new token with refreshToken
    
    logout();
    window.location = "/login";
  }

  if (!expectedError) {
    console.log(error);
    toast.error("An unexpected error occured");
  }

  return Promise.reject(error);
});

function setToken(authToken, refreshToken){
  axios.defaults.headers.common["x-auth-token"] = authToken;
  axios.defaults.headers.common["x-refresh-token"] = refreshToken;
}

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken, 
}

export default httpService

import httpService from "./httpService";
import * as config from "../config.json";
import jwtDecode from "jwt-decode";

const apiEndpoint = config.apiEndpoint + "/user.php";
const authTokenKey = config.authTokenKey;
const refreshTokenKey = config.refreshTokenKey;

async function login(username, password) {
  const { data } = await httpService.post(apiEndpoint, {
    username,
    password,
  });

  localStorage.setItem(authTokenKey, data.authToken);
  localStorage.setItem(refreshTokenKey, data.refreshToken);
  
  setToken();
  // httpService.setToken(getAuthToken(), getRefreshToken());
}

function logout() {
  localStorage.removeItem(authTokenKey);
}

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(authTokenKey);
    if(jwt){
      return jwtDecode(jwt);
    }

    return false;
  } catch (ex) {
    return null;
  }
}

function getAuthToken(){
  return localStorage.getItem(authTokenKey);
}

function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey);
}

function setToken() {
  httpService.setToken(getAuthToken(), getRefreshToken());
}

// function getJwt() {
//   return localStorage.getItem("token");
// }

const authService = {
  login,
  logout,
  getCurrentUser,
  setToken
}

export default authService;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import auth from "../services/authService";
import { useForm } from "react-hook-form";
import {
  FormControl,
  Button,
  Input,
  InputLabel,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import logo from "../img/hammer_logo_black web.png";
import "./login.css";

function Login(props) {
  const user = auth.getCurrentUser();
  useEffect(() => {
    //already logged in
    if (user) {
      props.history.push("/");
    }
  });

  const { register, handleSubmit, errors } = useForm();
  const [data, setData] = useState({});

  const style = {
    margin: {
      margin: "1rem 0",
    },
  };
  const handleChange = (e) => {
    let newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };
  const onSubmit = async (e) => {
    try {
      const { username, password } = data;
      await auth.login(username, password);
      props.onLogin();
      props.history.push("/");
    } catch (ex) {
      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 400)
      ) {
        toast.error(ex.response.data);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="login-root">
        <div className="login-bg">
          <div className="login-container">
            <img style={{ marginTop: "35px " }} width="200" src={logo} alt="" />
            <div className="login-form">
              <Typography variant="h4" component="h2">
                Log in to your Account
              </Typography>
              <form action="" onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  fullWidth
                  style={style.margin}
                  error={errors.username ? true : false}
                >
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <Input
                    id="username"
                    label="Username"
                    name="username"
                    onChange={handleChange}
                    inputRef={register({ required: true })}
                  />
                  {errors.username && errors.username.type === "required" ? (
                    <FormHelperText id="component-helper-text">
                      Username is required
                    </FormHelperText>
                  ) : (
                    <React.Fragment />
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  style={style.margin}
                  error={errors.password ? true : false}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    onChange={handleChange}
                    inputRef={register({ required: true, minLength: 8 })}
                  />
                  {errors.password ? (
                    <FormHelperText>
                      {errors.password.type === "required"
                        ? "Password is required"
                        : null}
                      {errors.password.type === "minLength"
                        ? "Minimum length is 8"
                        : null}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <FormControl fullWidth style={style.margin}>
                  <Button type="submit" variant="contained" color="primary">
                    Log in
                  </Button>
                </FormControl>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
